<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>Create New User</h5>
				<div class="p-fluid formgrid grid">
					<div class="field col-12 md:col-6">
						<label for="newusername">Username</label>
						<InputText v-model="uname" id="newusername" type="text"/>
					</div>
					<div class="field col-12 md:col-6">
						<label for="displayname">Display Name</label>
						<InputText v-model="dname" id="displayname" type="text"/>
					</div>
					<div class="field col-12 md:col-6">
						<label for="newpassword">Password</label>
						<InputText v-model="passwd" id="newpassword" type="password"/>
					</div>
					<div class="field col-12 md:col-6">
						<label for="newconfirmpassword">Confirm Password</label>
						<InputText v-model="cpasswd" id="newconfirmpassword" type="password"/>
					</div>
					<div class="field col-12">
						<Button label="Submit" class="mr-2 mb-2" @click="saveUinfo"></Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			uname: '',
			dname: '',
			passwd: '',
			cpasswd: ''
		}),
		methods: {
			saveUinfo () {
				let that = this
				if(this.passwd !== this.cpasswd) {
					this.$toast.add({severity:'error', summary:'Oops', detail:'Password and Confirm Password must match!', life: 3000});
					return
				}
				if(! /^[a-z0-9]+$/i.test(this.uname)) {
					this.$toast.add({severity:'error', summary:'Oops', detail:'Username can only contain alphanumeric characters!', life: 3000});
					return
				}
				if(this.uname === '' || this.dname === '' || this.passwd === '' || this.cpasswd === '') {
					this.$toast.add({severity:'error', summary:'Oops', detail:'All fields are mandatory!', life: 3000});
					return
				}
				this.axios.put('/user', {
					username: this.uname,
					dname: this.dname,
					password: this.passwd,
					authToken: this.$store.state.sessionUnique
				}).then(function (response) {
					that.$toast.add({severity:'success', summary:'Success', detail:'New user has been created!', life: 3000});
					console.log(response.data)
					that.uname = ''
					that.dname = ''
					that.passwd = ''
					that.cpasswd = ''
				}, function (response) {
					console.log(response)
					that.$toast.add({severity:'error', summary:'Error', detail:'Something went wrong!', life: 3000});
				})
			}
		}
	}
</script>
